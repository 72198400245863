import React, {PropsWithChildren} from "react";
import {Box, Grid} from "@material-ui/core";
import EnrollmentStepper from "./component/EnrollmentStepper";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import paths from "./config/router-paths";
import Enroll from "./Enroll";
import EnrollmentForm from "./EnrollmentForm";
import Receive from "./Receive";
import Layout from "../shared/Layout";
import CreateAccount from "./CreateAccount";
import useTemporalData from "../shared/hooks/useTemporalData";
import {setStoredUser} from "../shared/StoredUser";

export default (props: RouteComponentProps<{ countyName?: string }>) => {

  return <Layout SideComponent={EnrollmentStepper} {...props}>
    <div className={'h-8'} />
    <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
      <Switch>
          <Route exact path={paths.receive} component={Receive} />
          <Route exact path={paths.enroll + '/:planId/:zip/:countyName/:quoteId?'} component={Enroll} />
          <Route exact path={paths.personalDetails + '/:planId/:zip/:countyName/:quoteId?'} component={EnrollmentForm} />
          <Route exact path={paths.createAccount + '/:planId/:zip/:countyName/:quoteId?'} component={CreateAccount} />
      </Switch>
    </Box>
  </Layout>
}

