import React from 'react';
import PlanList from "./PlanList";
import {Box, Grid} from "@material-ui/core";
import PlanListSideContent from "./PlanListSideContent";
import ScrollableLayout from "../../shared/ScrollableLayout";
import LoginWidget from "./LoginWidget";
import {QuoteContext} from "./QuoteContext";


export default () => {

  const {pin} = React.useContext(QuoteContext);

  return <ScrollableLayout pin={pin} sideHeaderContent={<LoginWidget/>} SideComponent={PlanListSideContent}>
      <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
          <PlanList />
      </Box>
    </ScrollableLayout>
}
