import {Box, Typography} from "@material-ui/core";
import React from "react";

export function PlanSectionTitle({title}: { title: string }) {
  return (
    <Box justifyContent={"center"} width={1} textAlign={"center"} mt={1} mb={1}>
      <Typography variant={"h1"} color={'textPrimary'}><strong>{title}</strong></Typography>
    </Box>
  )
}
