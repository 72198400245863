import React from "react";
import paths from "../config/router-paths";
import UpdateZipModal from "./components/UpdateZipModal";
import useQuestionnaireRequest from "../../shared/hooks/useQuestionnaireRequest";
import {getQuoteId} from "../../shared/QuoteId";
import {KeycloakContext} from "../../shared/KeycloakContext";
import {useUserProfileLazy} from "../../Private/hooks/useUserProfile";
import {getStoredUser, setStoredUser} from "../../shared/StoredUser";

export default function ZipSelection() {
  const [open, setOpen] = React.useState(false);
  const quoteId = getQuoteId();
  const [getProfile, {data: profileData}] = useUserProfileLazy();
  const {authenticated} = React.useContext(KeycloakContext);
  const [getQuote, data] = useQuestionnaireRequest(res => {
    if (!!res?.personalDetails?.zip) {
      document.location.href = paths.plans + res?.personalDetails?.zip;
    } else {
      setOpen(true);
    }
  });

  React.useEffect(() => {
    if (authenticated) {
      getProfile()
    } else {
      if (quoteId) {
        getQuote({variables: {id: quoteId}})
      } else {
        setOpen(true);
      }
    }
  }, [quoteId, authenticated])

  React.useEffect(() => {
    if (profileData?.userProfile) {
      if (profileData?.userProfile?.zip && profileData?.userProfile?.countyName) {
        document.location.href = paths.plans + profileData.userProfile.zip + '/' + profileData?.userProfile?.countyName;
      } else {
        setOpen(true);
      }
    }
  }, [profileData])

  return <>
      <UpdateZipModal title={'Enter your zip code'}
                      subtitle={'We will load plan results in your area.'}
                      onClose={(zip?: string, county?: string) => {
                        if (zip && county) {
                          document.location.href = paths.plans + zip + '/' + county;
                          setStoredUser({...getStoredUser(), countyName: county})
                        }
                      }}
                      open={open}
                      disableBackdropClick={true}
                      disableSidePadding={true}
                      buttonLabel={'Continue'} />
    </>;
}
