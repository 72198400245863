import React from "react";
import PlanFilter from "./PlanFilter";
import HealthcarePreferences from "./HealthcarePreferences";
import MonthlyCostCalculator from "./MonthlyCostCalculator";
import AscendDivider from "../../shared/components/AscendDivider";
import Terms from "../../shared/SidePanel/Terms";

const PlanListSideContent = () => {
  return <>
    <HealthcarePreferences />
    <AscendDivider mb={'24px'} />
    <MonthlyCostCalculator />
    <AscendDivider mt={'28px'} />
    <PlanFilter />
    <Terms />
  </>
}

export default PlanListSideContent;
