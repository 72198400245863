import React, { Profiler } from 'react';
import {RouteComponentProps} from "react-router-dom";
import { PlanFilterProvider} from "./PlanFilterContext";
import DashboardPage from "./DashboardLayout";
import useTemporalData from "../../shared/hooks/useTemporalData";
import {setStoredUser} from "../../shared/StoredUser";

export default (props: RouteComponentProps<{ zip: string, countyName: string }>) => {
  const {zip, countyName} = props.match.params;

  return <PlanFilterProvider initFilter={{zip, countyName, companies: [], planTypes: [], rating: [], SNPTypes: [], extraBenefits: []}}>
      <DashboardPage />
  </PlanFilterProvider>
}
