import React from 'react';
import {Box, Divider, Grid, Typography} from "@material-ui/core";

export function JsxPlanDetail({title, content}: { title: string | JSX.Element, content: any }) {
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"start"} my={"20px"}>
        <Grid item xs={5}>
          <Typography variant={"h5"}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Box display={"flex"} flexGrow={2} flexDirection={"column"}>
            {content}
          </Box>
        </Grid>
      </Box>
      <Divider/>
    </>
  )
}
