import React from 'react';
import './App.css';
import '../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {client, setErrorHandler} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import Quote from "./Quote";
import Home from "./Home";
import {SnackbarProvider} from "notistack";
import {ComparisonProvider} from "../App/PlanComparison/ComparisonContext";

function App() {
  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  return (
    <ApolloProvider client={client}>
      <SnackbarProvider preventDuplicate classes={{
        variantSuccess: 'success-snack'
      }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}>
        <BrowserRouter>
          <Switch>
            <Route exact path={paths.questionnaire} component={Quote} />
            <Route path={paths.questionnaire + '/:routeName'} component={Quote} />
            <Route exact path={paths.home} component={Home} />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </ApolloProvider>
  );
}

export default App;
